import React, { useState } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';

function Dashboard() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const email = sessionStorage.getItem('email');
    
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        localStorage.removeItem('jwtToken');
        navigate('/');
    };

    const handleSyncBilling = async () => {
        try {
            const response = await axios.get('https://api.greenpointev.com/inindiatech/v1/builder/6sch055g56859');
            console.log('Billing sync response:', response.data);
            // Optionally, you can add logic here to display a success message or update UI
        } catch (error) {
            console.error('Error syncing billing table:', error);
            // Handle error scenarios, such as displaying an error message to the user
        }
    };

    return (
        <div className="dashboard-container">
            <div className="sidebar">
                <ul>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li><Link to="profile">Profile</Link></li>
                    <li><Link to="settings">Settings</Link></li>
                    <li onClick={handleLogout}>Logout</li>
                </ul>

                <div className="user-info-bottom">
                    <div className="user-info" onClick={toggleDropdown}>
                        {/* User Icon or Avatar */}
                        <div className="user-avatar">
                            <i className="fa fa-user"></i> {/* Font Awesome user icon */}
                        </div>
                        <span>{email}</span>
                        <i className={`fa fa-chevron-${dropdownOpen ? 'down' : 'up'}`}></i>
                    </div>

                    {/* Dropdown menu */}
                    {dropdownOpen && (
                        <div className={`dropdown-menu ${dropdownOpen ? 'visible' : ''}`}>
                            <ul>
                                <li>Profile</li>
                                <li>Settings</li>
                                <li onClick={handleLogout}>Logout</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            {/* Dynamic Content Area */}
            <div className="content">
                <button className="sync-button" onClick={handleSyncBilling}>
                    Sync Billing Table
                </button>

                <Routes>
                    {/* Define other routes as needed */}
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;
