import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './auth/LoginPage';
import NotFoundPage from './NotFoundPage';
import './App.css';
import Dashboard from './admin/Dashboard';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Fallback route */}
        <Route path="/dashboard/*" element={<Dashboard />} />
        {/* <Route path="/dashboard/profile" element={<Profile />} />
                    <Route path="/dashboard/settings" element={<Settings />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
