// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from '../gpev_logo.png';

const staticUser = {
  email: 'rdcummings0@gmail.com',
  password: '7h!sIs@Str0ngP@ssw0rd'
};

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginStatus(''); // Reset login status message

    try {
      if (email !== staticUser.email || password !== staticUser.password) {
        setLoginStatus('Login failed. Please check your credentials.');
        return;
      }

      // Store token and role in session storage
      sessionStorage.setItem('token', 'PkLv1ESMhqIYd0nLMwNLDIbcEPTNtaed2xpHsD969Npy4HI+N');
      sessionStorage.setItem('role', 'admin');
      sessionStorage.setItem('email', email);

      // Redirect to the Dashboard component
      navigate('/dashboard');
    } catch (error) {
      setLoginStatus('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <img src={logo} alt="Logo" className="login-logo" width={300} />
        <p className="tagline">INDEPENDENT EV CHARGING</p>
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Sign In</button>
        </form>
        {loginStatus && (
          <p className="login-status">{loginStatus}</p>
        )}
        <div className="footer-text">GreenPointEV</div>
      </div>
    </div>
  );
}

export default LoginPage;
